import { changeLocale } from "gatsby-plugin-intl"

const DEFAULT_LANG = "es"
const LOCALES = ["en", "es"]

export function getLanguages() {
  return LOCALES //May got by an API in the future
}

export function getCurrentLanguage() {
  if (
    typeof window !== "undefined" &&
    localStorage &&
    localStorage.getItem("gatsby-intl-language")
  ) {
    return localStorage.getItem("gatsby-intl-language")
  }
  return DEFAULT_LANG
}

export function changeLanguage(to = DEFAULT_LANG) {
  changeLocale(to)
}
