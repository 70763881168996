import React, { useEffect } from "react"
import { Icon, Menu } from "antd"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import LocalizedAniLink from "../components/localizedAniLink"
import { getUserGroups } from "../services/auth"
import { getMenuItems } from "../services/menu"
import { checkPermissions } from "../services/auth/permissions/permissions"

import "antd/lib/style"
import "../assets/styles"
import useStateObject from "../hooks/useStateObject"

const { SubMenu } = Menu

const CustomMenu = ({ visible, selected, collapsed }) => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    selected,
    collapsed,
    visible,
    items: [],
    pathArray: "",
  })

  useEffect(() => {
    const pathArray =
      window && window.location && window.location.pathname
        ? window.location.pathname.split("/")
        : ""
    getUserGroups()
      .then(getMenuItems)
      .then(items => setState({ items, pathArray }))
  }, [])

  const handleCollapse = e => {
    e.preventDefault()
    setState({ collapsed: !state.collapsed })
  }

  const renderIcon = icon => {
    if (typeof icon === "string") {
      return <Icon type={icon} />
    } else {
      return <Icon component={icon} />
    }
  }

  const renderItem = item => {
    const selectedItems = [...selected, ...state.pathArray.slice(2)]
    if (
      selectedItems.includes("ingest") ||
      selectedItems.includes("context") ||
      selectedItems.includes("dictionary")
    ) {
      selectedItems.push("ingests")
    }
    if (selectedItems.includes("dataset")) {
      selectedItems.push("machine-learning")
    }
    if (!item.permissions || checkPermissions(item.permissions, true)) {
      if (item.items) {
        return (
          <SubMenu
            key={item.key}
            title={
              <span
                className={
                  selectedItems.includes(item.key)
                    ? "menu-item selected"
                    : "menu-item"
                }
              >
                {item.icon ? renderIcon(item.icon) : ""}
                <span>{intl.formatMessage({ id: item.title })}</span>
              </span>
            }
          >
            {item.items.map(i => renderItem(i))}
          </SubMenu>
        )
      } else {
        if (item.externalLink) {
          return (
            <Menu.Item key={item.key}>
              <a
                {...item.externalLink}
                className={
                  selectedItems.includes(item.key)
                    ? "menu-item selected"
                    : "menu-item"
                }
              >
                {item.icon ? renderIcon(item.icon) : ""}
                <span>{intl.formatMessage({ id: item.title })}</span>
              </a>
            </Menu.Item>
          )
        } else {
          return (
            <Menu.Item key={item.key}>
              <LocalizedAniLink
                fade
                to={item.to}
                className={
                  selectedItems.includes(item.key)
                    ? "menu-item selected"
                    : item.label
                    ? "no-hover"
                    : "menu-item"
                }
              >
                {item.icon ? renderIcon(item.icon) : ""}
                <span>{intl.formatMessage({ id: item.title })}</span>
              </LocalizedAniLink>
            </Menu.Item>
          )
        }
      }
    }
  }

  return (
    <Menu
      className={visible ? "menuLinks visible" : "menuLinks hidden"}
      theme="light"
      forceSubMenuRender={true}
      selectedKeys={state.selected}
      mode="vertical"
    >
      {(state.items || []).map(item => renderItem(item))}
      <span className="menuTrigger" onClick={handleCollapse} />
    </Menu>
  )
}

CustomMenu.propTypes = {
  selected: PropTypes.array,
  collapsed: PropTypes.bool,
}

CustomMenu.defaultProps = {
  selected: ["home"],
  collapsed: false,
}

export default CustomMenu
