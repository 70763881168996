import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getLinkTo = to => {
  const { language, routed } =
    (typeof window !== "undefined" && window.___gatsbyIntl) || {}

  return routed ? `/${language}${to}` : `${to}`
}

const LocalizedAniLink = ({ to, children, ...rest }) => {
  const link = getLinkTo(to)

  return (
    <AniLink fade to={link} {...rest}>
      {children}
    </AniLink>
  )
}

export default LocalizedAniLink
