import React, { useEffect, useState } from "react"
import { Col, Dropdown, Icon, Layout, Menu, Row } from "antd"
import logo from "../assets/images/img-logo-small.svg"
import { getUser, logout } from "../services/auth"
import { navigate, useIntl } from "gatsby-plugin-intl"
import LocalizedAniLink from "../components/localizedAniLink"
import {
  changeLanguage,
  getCurrentLanguage,
  getLanguages,
} from "../services/i18n"
import Gravatar from "react-gravatar"
import { AiOutlineDown } from "react-icons/ai"
import es from "../assets/images/menu/spain-flag-icon.png"
import en from "../assets/images/menu/united-kingdom-flag-icon.png"
import PropTypes from "prop-types"

const { Header } = Layout

const flags = { es, en }

const CustomHeader = ({ toggleShowMenu }) => {
  const intl = useIntl()
  const [user, setUser] = useState(null)

  useEffect(() => {
    getUser()
      .then(value => {
        setUser(value)
      })
      .catch(_ => {
        setUser(null)
      })
  }, [])

  const renderChangeLanguageDropdown = () => {
    const menu = (
      <Menu key="3456666">
        {getLanguages().map(language => (
          <Menu.Item key={language} onClick={() => changeLanguage(language)}>
            <img
              src={flags[language]}
              width={20}
              height={20}
              alt="flag"
              style={{ borderRadius: "50%" }}
            />
            &nbsp;&nbsp;
            {language.toUpperCase()}
          </Menu.Item>
        ))}
      </Menu>
    )
    const currentLanguage = getCurrentLanguage()
    const currentFlag = flags[currentLanguage]
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginRight: "15px",
        }}
      >
        <Dropdown overlay={menu} arrow={true}>
          <span>
            <img
              src={currentFlag}
              width={20}
              height={20}
              alt="flag"
              style={{ borderRadius: "50%" }}
            />
            &nbsp;&nbsp;
            {currentLanguage.toUpperCase()}
            <Icon
              component={AiOutlineDown}
              style={{ color: "white", paddingLeft: "10px" }}
            />
          </span>
        </Dropdown>
      </div>
    )
  }
  const renderUserDropdown = () => {
    const menu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="profile">
          <Icon type="user" size="large" />
          {intl.formatMessage({
            id: "profile",
          })}
        </Menu.Item>
        <Menu.Item key="logout">
          <Icon type="logout" size="large" />
          {intl.formatMessage({
            id: "logout",
          })}
        </Menu.Item>
      </Menu>
    )
    if (user) {
      return (
        <div>
          <Dropdown overlay={menu}>
            <Icon component={AiOutlineDown} style={{ color: "white" }} />
          </Dropdown>
        </div>
      )
    }
    return null
  }

  const handleMenuClick = ({ key }) => {
    if (key === "profile") {
      navigate(`/user/${user.id}`)
    } else if (key === "logout") {
      logout()
    }
  }

  const renderMenuIcon = () => {
    if (user) {
      return <div className="menu-icon" onClick={() => toggleShowMenu()} />
    }
    return null
  }

  return (
    <Header style={{ zIndex: "10" }}>
      <Row type="flex" justify="end">
        <Col xs={2} sm={4} md={6} lg={8} xl={10} className="menu-header">
          {renderMenuIcon()}
          <div className="logo" data-testid="cph-logo">
            <LocalizedAniLink fade to="/">
              <img data-testid="logo" alt="Knolar" src={logo} />
            </LocalizedAniLink>
          </div>
        </Col>

        <Col xs={20} sm={16} md={12} lg={8} xl={4} />
        <Col xs={2} sm={4} md={6} lg={8} xl={10} className="avatarMenu">
          {renderChangeLanguageDropdown()}
          <div
            style={{
              display: "flex",
              padding: 0,
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            {user ? (
              <Gravatar
                className="rounded-corner"
                email={user.email}
                size={40}
              />
            ) : null}
            {renderUserDropdown()}
          </div>
          <div style={{ display: "block" }}>{user ? user.email : null}</div>
        </Col>
      </Row>
    </Header>
  )
}

CustomHeader.propTypes = {
  toggleShowMenu: PropTypes.func.isRequired,
}

export default CustomHeader
