import { FiUsers as icUsers } from "react-icons/fi"
import { MdHelpOutline as icHelp } from "react-icons/md"
import { RiPsychotherapyLine as icMl } from "react-icons/ri"
import { generateDeploymentHostname } from "../network"
import icIngest from "../../assets/icons/ic_ingestas.svg"
import icTool from "../../assets/icons/ic_cphTool.svg"
import icMonitor from "../../assets/icons/ic_monitorizacion.svg"
import icAnalytics from "../../assets/icons/ic_analytics.svg"
// import icPrice from "../../assets/icons/ic_precios.svg"
// import icUsers from "../../assets/icons/ic_users.svg"
import icVisualization from "../../assets/icons/ic_visualizacion.svg"
import icApis from "../../assets/icons/ic_apis.svg"
// import icHelp from "../../assets/icons/ic_help.svg"

export default [
  {
    key: "home",
    to: "/",
    icon: "appstore",
    title: "menu-home",
  },
  {
    key: "ingests",
    title: "models-ingestions",
    icon: icIngest,
    permissions: [
      "dictionary:write",
      "dictionary:read",
      "dictionary:delete",
      "site:write",
      "site:read",
      "site:delete",
      "source:write",
      "source:read",
      "source:delete",
      "prefix:write",
      "prefix:read",
      "prefix:delete",
      "business-unit:write",
      "business-unit:read",
      "business-unit:delete",
      "ingest:write",
      "ingest:read",
      "ingest:delete",
    ],
    items: [
      {
        key: "dictionary",
        title: "models-dictionary",

        permissions: [
          "dictionary:write",
          "dictionary:read",
          "dictionary:delete",
        ],
        items: [
          {
            key: "dictionary-index",
            to: "/dictionary",

            title: "menu-dictionary-management",
            permissions: ["dictionary:read", "dictionary:delete"],
          },
          {
            key: "dictionary-form",
            to: "/dictionary/form",

            title: "menu-dictionary-create",
            permissions: ["dictionary:write"],
          },
        ],
      },
      {
        key: "context",
        title: "contextualization",
        permissions: [
          "site:write",
          "site:read",
          "site:delete",
          "source:write",
          "source:read",
          "source:delete",
          "prefix:write",
          "prefix:read",
          "prefix:delete",
          "business-unit:write",
          "business-unit:read",
          "business-unit:delete",
        ],
        items: [
          {
            key: "site",
            to: "/context/site",

            title: "models-sites",
            permissions: ["site:write", "site:read", "site:delete"],
          },
          {
            key: "source",
            to: "/context/source",

            title: "models-sources",
            permissions: ["source:write", "source:read", "source:delete"],
          },
          {
            key: "prefix",
            to: "/context/prefix",

            title: "models-prefixes",
            permissions: ["prefix:write", "prefix:read", "prefix:delete"],
          },
          {
            key: "business-unit",
            to: "/context/business-unit",

            title: "models-business-units",
            permissions: [
              "business-unit:write",
              "business-unit:read",
              "business-unit:delete",
            ],
          },
        ],
      },
      {
        key: "ingest",
        title: "models-ingestions",

        permissions: ["ingest:write", "ingest:read", "ingest:delete"],
        items: [
          {
            key: "ingestion-index",
            to: "/ingest",
            title: "menu-ingestions-management",
            permissions: ["ingest:read", "ingest:delete"],
          },
          {
            key: "ingest-new",
            to: "/ingest/options",
            title: "menu-ingestions-create",
            permissions: ["ingest:write"],
          },
        ],
      },
    ],
  },
  {
    key: "cph-tool",
    icon: icTool,
    title: "menu-cph-tool",
    items: [
      {
        key: "tutorial-gateway",
        title: "gateway",
        to: "/tutorials/cph-tool/gateway",
      },
      {
        key: "mqtt-accumulator",
        title: "mqtt-accumulator",
        to: "/tutorials/cph-tool/mqtt-accumulator",
      },
    ],
  },
  {
    key: "consume-devApi",
    title: "menu-api",
    icon: icApis,
    permissions: ["developers-portal:access"],
    items: [
      {
        key: "consume-devApi",
        title: "menu-developers-portal",
        permissions: ["developers-portal:access"],
        externalLink: {
          href: `https://${generateDeploymentHostname("developers-portal")}/`,
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
    ],
  },
  {
    key: "machine-learning",
    title: "menu-ml",
    icon: icMl,
    permissions: ["dataset:read"],
    items: [
      {
        key: "datasets",
        title: "menu-datasets",
        to: "/dataset",
      },
    ],
  },
  {
    key: "alarms",
    icon: icMonitor,
    title: "menu-monitoring",
    permissions: ["alarm:read", "alarm:delete"],
    items: [
      {
        key: "alarms-index",
        to: "/alarms",
        title: "menu-alarms-management",
      },
    ],
  },
  {
    key: "visualization",
    icon: icVisualization,
    title: "menu-visualization",
    permissions: ["monitoring:access"],
    items: [
      {
        key: "timeseries",
        to: "/visualization/timeseries",
        title: "menu-timeseries",
        permissions: ["prefix:read"],
      },
      {
        key: "grafana",
        title: "menu-grafana",
        externalLink: {
          href: `https://${generateDeploymentHostname("monitoring")}`,
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
    ],
  },
  // {
  //   key: "datalake",
  //   icon: "desktop",
  //   title: "menu-datalake",
  //   permissions: ["datalake:access"],
  //   items: [
  //     {
  //       key: "datalake-consume",
  //       to: "/datalake/consume",
  //       icon: "desktop",
  //       title: "menu-consumption",
  //     },
  //   ],
  // },
  // {
  //   key: "persistence",
  //   icon: "database",
  //   title: "menu-persistence",
  //   permissions: ["druid-ha:access"],
  //   externalLink: {
  //     href: `https://${generateDeploymentHostname(
  //       "druid-ha"
  //     )}/unified-console.html`,
  //     target: "_blank",
  //     rel: "noopener noreferrer",
  //   },
  // },
  {
    key: "consume",
    icon: icAnalytics,
    title: "menu-analytics",
    permissions: [
      "jupyter:access",
      "superset:access",
      "addin:access",
      "addin-web:access",
    ],
    items: [
      {
        key: "jupyter",
        title: "menu-jupyter",
        to: "/consume/jupyter",
        permissions: ["jupyter:access"],
      },
      {
        key: "superset",
        title: "menu-superset",
        permissions: ["superset:access"],
        externalLink: {
          href: `https://${generateDeploymentHostname("superset")}/`,
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      {
        key: "rapidminer",
        title: "menu-rapidminer",
        to: "/tutorials/consume/rapidminer",
      },
      {
        key: "excel-addin",
        title: "menu-exceladdin",
        to: "/tutorials/consume/excel-addin",
        permissions: ["addin:access", "addin-web:access"],
      },
      {
        key: "business-intelligence-tools",
        title: "menu-bi-tools",
        to: "/tutorials/consume/business-intelligence-tools",
      },
    ],
  },
  {
    title: "general-div-text",
    key: "general-div-text",
    label: true,
  },
  {
    key: "help-root",
    icon: icHelp,
    title: "menu-help",
    items: [
      {
        key: "doc",
        title: "menu-doc",
        externalLink: {
          href: "https://techdocs.knolar.io/",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      {
        key: "help",
        title: "menu-help",
        to: "/help-root/help",
      },
      {
        key: "faq",
        title: "menu-faq",
        to: "/help-root/faq",
      },
    ],
  },
  {
    key: "user",
    icon: icUsers,
    title: "menu-user",
    permissions: ["user:write", "user:read", "user:delete", "role:read"],
    items: [
      {
        key: "user-list",
        title: "menu-user-management",
        to: "/user",
        permissions: ["user:read"],
      },
      {
        key: "new-user",
        title: "menu-user-new",
        to: "/user/new-user",
        permissions: ["user:write"],
      },
      {
        key: "role-list",
        title: "menu-role-managment",
        to: "/role",
        permissions: ["role:read"],
      },
      {
        key: "new-role",
        title: "menu-role-new",
        to: "/role/new-role",
        permissions: ["role:write"],
      },
    ],
  },
]
