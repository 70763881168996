import { useState, useEffect, useRef } from "react"

const useStateObject = object => {
  const [customState, setCustomState] = useState(object)
  const callbackFn = useRef(null)

  const setState = (state, callback = null) => {
    const merged = {
      ...customState,
      ...state,
    }
    callbackFn.current = callback
    setCustomState(merged)
  }

  useEffect(() => {
    if (callbackFn.current) {
      callbackFn.current(customState)
    }
  }, [customState])

  return [customState, setState]
}

export default useStateObject
